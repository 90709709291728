import axios from "axios";

const baseUrl = "http://localhost/api/index.php";
const baseUrl_prod = "https://hoijer.net/api/index.php";

const createInstance = (jwt) => {
   let useUrl = baseUrl_prod;
   if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
      useUrl = baseUrl;
   }
   console.log("using url: ", useUrl);

   const instance = axios.create({
      baseURL: useUrl,
      timeout: 5000,
      headers: { "X-Custom-Header": "foobar", Authorization: `Bearer ${jwt}` },
   });

   return instance;
};

// logData:
// {
//    message: "string",
//    category: "string",
// }
export const logEventPromise = (userContext, logData) => {
   return new Promise(function (resolve, reject) {
      const instance = createInstance(userContext.accessToken);
      instance
         .post("/logEvent", logData)
         .then((resp) => {
            console.log("Loggat event:");
            console.log(resp?.data);
            resolve(resp);
         })
         .catch((ex) => {
            console.error("LogEvent ex:", ex);
            reject(ex);
         });
   });
};

export const getLogByCategoryPromise = (userContext, logParams) => {
   return new Promise(function (resolve, reject) {
      const instance = createInstance(userContext.accessToken);
      instance
         .get(
            `/getLogByCategory?category=${logParams.category}&limit=${logParams.limit}`
         )
         .then((resp) => {
            console.log("getLogByCatPromise ok:");
            console.log(resp.data);
            resolve(resp);
         })
         .catch((exception) => {
            console.error("getLogByCatPromise ex:", exception);
            reject(exception);
         });
   });
};
