import React from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui-ikoner
import { Apps, People } from "@material-ui/icons";
//FA-ikoner
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";
import { UserContext } from "app";

import { AdminPortal } from "@frontegg/react";
import { isAdmin, isConsultantBasic, isReadOnly } from "core/UserFunctions";

const useStyles = makeStyles(styles);

export default function HeaderLinks() {
   const classes = useStyles();
   const navigate = useNavigate();

   const user = React.useContext(UserContext);

   const openAccount = () => {
      AdminPortal.show();
   };

   return (
      <List className={classes.list}>
         {isAdmin(user) && (
            <ListItem className={classes.listItem}>
               <CustomDropdown
                  noLiPadding
                  buttonText="Components"
                  buttonProps={{
                     className: classes.navLink,
                     color: "transparent",
                  }}
                  buttonIcon={Apps}
                  dropdownList={[
                     <Link
                        key="comp"
                        to="/components"
                        className={classes.dropdownLink}
                     >
                        All components
                     </Link>,
                     <a
                        href="https://creativetimofficial.github.io/material-kit-react/#/documentation?ref=mkr-navbar"
                        target="_blank"
                        rel="noreferrer"
                        className={classes.dropdownLink}
                        key="allComps"
                     >
                        Documentation
                     </a>,
                  ]}
               />
            </ListItem>
         )}
         {(isAdmin(user) || isReadOnly(user)) && (
            <ListItem className={classes.listItem}>
               <Button
                  color="transparent"
                  className={classes.navLink}
                  onClick={() => navigate("/all-profiles")}
               >
                  <People className={classes.icons} /> Profiler
               </Button>
            </ListItem>
         )}
         {(isAdmin(user) || isConsultantBasic(user)) && (
            <ListItem className={classes.listItem}>
               <Button
                  color="transparent"
                  className={classes.navLink}
                  onClick={() => navigate("/edit-page")}
               >
                  <FontAwesomeIcon icon={faUserEdit} />
                  {isAdmin(user)
                     ? "Redigera Profiler"
                     : isConsultantBasic(user)
                     ? "Redigera min profil"
                     : "Skapa min profil"}
               </Button>
            </ListItem>
         )}
         <ListItem className={classes.listItem}>
            <Tooltip
               id="instagram-twitter"
               title="Följ oss på twitter"
               placement={window.innerWidth > 959 ? "top" : "left"}
               classes={{ tooltip: classes.tooltip }}
            >
               <Button
                  href="https://twitter.com/CreativeTim?ref=creativetim"
                  target="_blank"
                  color="transparent"
                  className={classes.navLink}
               >
                  <i className={classes.socialIcons + " fab fa-twitter"} />
                  <Box
                     component="span"
                     sx={{
                        display: {
                           xs: "inline",
                           md: "none",
                        },
                     }}
                  >
                     {" Twitter"}
                  </Box>
               </Button>
            </Tooltip>
         </ListItem>
         <ListItem className={classes.listItem}>
            <Tooltip
               id="instagram-facebook"
               title="Följ oss på facebook"
               placement={window.innerWidth > 959 ? "top" : "left"}
               classes={{ tooltip: classes.tooltip }}
            >
               <Button
                  color="transparent"
                  href="https://www.facebook.com/CreativeTim?ref=creativetim"
                  target="_blank"
                  className={classes.navLink}
               >
                  <i className={classes.socialIcons + " fab fa-facebook"} />
                  <Box
                     component="span"
                     sx={{
                        display: {
                           xs: "inline",
                           md: "none",
                        },
                     }}
                  >
                     {" Facebook"}
                  </Box>
               </Button>
            </Tooltip>
         </ListItem>
         <ListItem className={classes.listItem}>
            <Tooltip
               id="instagram-tooltip"
               title="Följ oss på instagram"
               placement={window.innerWidth > 959 ? "top" : "left"}
               classes={{ tooltip: classes.tooltip }}
            >
               <Button
                  color="transparent"
                  href="https://www.instagram.com/CreativeTimOfficial?ref=creativetim"
                  target="_blank"
                  className={classes.navLink}
               >
                  <i className={classes.socialIcons + " fab fa-instagram"} />
                  <Box
                     component="span"
                     sx={{
                        display: {
                           xs: "inline",
                           md: "none",
                        },
                     }}
                  >
                     {" Instagram"}
                  </Box>
               </Button>
            </Tooltip>
         </ListItem>
         <ListItem className={classes.listItem}>
            <Tooltip
               id="account"
               title="Konto"
               placement={window.innerWidth > 959 ? "top" : "left"}
               classes={{ tooltip: classes.tooltip }}
            >
               <Button
                  color="transparent"
                  className={classes.navLink}
                  onClick={openAccount}
               >
                  <ManageAccountsIcon />
                  {" Mitt konto"}
               </Button>
            </Tooltip>
         </ListItem>
         <ListItem className={classes.listItem}>
            <Tooltip
               id="logout"
               title="Avsluta"
               placement={window.innerWidth > 959 ? "top" : "left"}
               classes={{ tooltip: classes.tooltip }}
            >
               <Button
                  color="transparent"
                  className={classes.navLink}
                  onClick={() => navigate("/account/logout")}
               >
                  <LogoutIcon />
                  {" Logga ut"}
               </Button>
            </Tooltip>
         </ListItem>
      </List>
   );
}
