import React, { useEffect } from "react";
import PropTypes from "prop-types";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/sectionStyle.js";

import { LogCategories } from "constants/logCategories";

import { getLogByCategoryPromise, logEventPromise } from "api/Log";
import { sendNewUserMail } from "api/Profiles";

const useStyles = makeStyles(styles);

export default function NewUserSection({ user }) {
   useEffect(() => {
      // console.log("NewUser, kollar om mail skickat...");
      getLogByCategoryPromise(user.context, {
         category: LogCategories.NewUser,
         limit: 1,
      })
         .then((result) => {
            const foundNewUserLog = result.data?.filter(
               (log) => log.user === user.context.email
            );
            console.log("foundNewUserLog: ", foundNewUserLog);
            if (!foundNewUserLog || foundNewUserLog.length === 0) {
               sendNewUserMail(user.context);
               logEventPromise(user.context, {
                  user: user.context.email,
                  message: "Ny användare, skickat mail.",
                  category: LogCategories.NewUser,
               }).then(() => {
                  // console.log("Ny user, skickar nytt mail. ", result);
               });
            } else {
               // console.log("NewUserLog hittat. skickar inget mail.");
            }
         })
         .catch((exception) => {
            console.log("Fel getLogByCat: ", exception);
         });
   }, []);

   const classes = useStyles();

   return (
      <div className={classes.section}>
         <GridContainer justifyContent="center">
            <GridItem cs={12} sm={12} md={8}>
               <h2 className={classes.title}>Ny användare</h2>
               <h4 className={classes.description}>
                  <p>
                     En ny användare skall inte kunna göra så mycket innan rätt
                     behörighet har getts i frontegg.
                     <br />
                     Fyll på med allmän info om vad som händer.
                  </p>
               </h4>
            </GridItem>
         </GridContainer>
      </div>
   );
}

NewUserSection.propTypes = {
   user: PropTypes.any,
};
