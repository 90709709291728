/* eslint-disable react/prop-types */
import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import StartSection from "./Sections/StartSection";
import { Navigate } from "react-router-dom";
import { UserContext } from "../../app";
import { isNewUser } from "core/UserFunctions";
import NewUserSection from "./Sections/NewUserSection";
// import axios from "axios";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// const fetchData = (jwt) => {
//    console.log(jwt);
//    const requestOptions = {
//       method: "GET",
//       headers: {
//          Authorization: `Bearer ${jwt}`,
//       },
//    };
//    fetch("http://localhost/api/index.php/allProfiles", requestOptions)
//       //fetch("https://hoijer.net/api/index.php?test=hansh", requestOptions)
//       .then((resp) => {
//          console.log(resp);
//          return resp.json();
//       })
//       .then((data) => {
//          console.log("fetch Success:", data);
//       })
//       .catch((error) => {
//          console.error("fetch Error:", error);
//       });
// };

export default function StartPage(props) {
   const classes = useStyles();
   const { ...rest } = props;

   const user = React.useContext(UserContext);

   if (!user.isUserAuthenticated) {
      // return <div>login user</div>;
      return <Navigate to="/account/login" />;
   }
   return (
      <div>
         <Header
            color="transparent"
            routes={dashboardRoutes}
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
               height: 1000,
               color: "white",
            }}
            checkPageTitleId="page-title"
            {...rest}
         />
         <Parallax
            filter
            image={require("assets/img/fabric-start.jpg").default}
            height="60vh"
         >
            <div className={classes.container}>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                     <h1 id="page-title" className={classes.title}>
                        Startsidan
                     </h1>
                     <h4>Någon lämplig text som beskriver vad som händer.</h4>
                  </GridItem>
               </GridContainer>
            </div>
         </Parallax>
         <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.container}>
               {isNewUser(user) && <NewUserSection user={user} />}
               {!isNewUser(user) && <StartSection />}
            </div>
         </div>
         <Footer />
      </div>
   );
}
