import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";

import emailjs from "emailjs-com";

import styles from "assets/jss/material-kit-react/sectionStyle.js";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const useStyles = makeStyles(styles);
const CONTACT_SERVICE = "service_gmail";
const CONTACT_FORM = "template_gmail_test";
const MAIL_USER = "user_CGt6RTTxMCIBHxz5CDWPa";

const emptyForm = {
   name: "",
   email: "",
   message: "",
};
export default function StartSection() {
   const [formData, setFormData] = useState(emptyForm);

   const [snackMessage, setSnackMessage] = useState({ text: "", severity: "" });

   const handleSendButton = () => {
      //alert("skickar meddelande: " + JSON.stringify(formData));
      const questionForm = document.getElementById("questionForm");
      emailjs.init(MAIL_USER);
      emailjs.sendForm(CONTACT_SERVICE, CONTACT_FORM, questionForm).then(
         function () {
            setSnackMessage({
               text: "Meddelande skickat!",
               severity: "success",
            });
            setFormData(emptyForm);
         },
         function (error) {
            setSnackMessage({
               text: "Kunde inte skicka meddelande..." + JSON.stringify(error),
               severity: "warning",
            });
         }
      );
   };

   const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
   });

   const handleSnackClose = (event, reason) => {
      if (reason === "clickaway") {
         return;
      }
      setSnackMessage({ text: "", severity: "" });
   };

   const classes = useStyles();
   return (
      <div className={classes.section}>
         <GridContainer justify="center">
            <GridItem cs={12} sm={12} md={8}>
               <h2 className={classes.title}>Texia</h2>
               <h4 className={classes.descriptionLeft}>
                  Texia är ett konsultföretag inom textilbranchen och som
                  tillhandahåller tjänster inom kvalité. Vill ni ha mer
                  information om vad vi kan erbjuda er så vänligen fyll i
                  formuläret nedan med era frågor.
                  <p>
                     <form id="questionForm">
                        <GridContainer>
                           <GridItem xs={12} sm={12} md={6}>
                              <CustomInput
                                 labelText="Ditt namn"
                                 id="name"
                                 formControlProps={{
                                    fullWidth: true,
                                 }}
                                 inputProps={{
                                    name: "from_name",
                                    onChange: (e) => {
                                       setFormData({
                                          ...formData,
                                          name: e.target.value,
                                       });
                                    },
                                    value: formData.name,
                                 }}
                              />
                           </GridItem>
                           <GridItem xs={12} sm={12} md={6}>
                              <CustomInput
                                 labelText="Din Email"
                                 id="email"
                                 name="user_email"
                                 formControlProps={{
                                    fullWidth: true,
                                 }}
                                 inputProps={{
                                    name: "user_email",
                                    onChange: (e) => {
                                       setFormData({
                                          ...formData,
                                          email: e.target.value,
                                       });
                                    },
                                    value: formData.email,
                                 }}
                              />
                           </GridItem>
                           <CustomInput
                              labelText="Meddelande"
                              id="message"
                              name="message"
                              formControlProps={{
                                 fullWidth: true,
                                 className: classes.textArea,
                              }}
                              inputProps={{
                                 name: "message",
                                 multiline: true,
                                 rows: 4,
                                 onChange: (e) => {
                                    setFormData({
                                       ...formData,
                                       message: e.target.value,
                                    });
                                 },
                                 value: formData.message,
                              }}
                           />
                           <GridItem xs={12} sm={12} md={4}>
                              <Button
                                 color="primary"
                                 onClick={handleSendButton}
                                 disabled={
                                    formData.name === "" ||
                                    formData.message === ""
                                       ? true
                                       : false
                                 }
                              >
                                 Skicka meddelande
                              </Button>
                           </GridItem>
                        </GridContainer>
                     </form>
                  </p>
               </h4>
            </GridItem>
         </GridContainer>
         <Snackbar
            autoHideDuration={5000}
            open={snackMessage.text === "" ? false : true}
            onClose={handleSnackClose}
            message={snackMessage.text}
            key={!"state.Transition.name"}
         >
            <Alert
               onClose={handleSnackClose}
               severity={snackMessage.severity}
               sx={{ width: "100%" }}
            >
               {snackMessage.text}
            </Alert>
         </Snackbar>
      </div>
   );
}
