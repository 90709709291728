import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Box from "@mui/material/Box";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import Section from "./Sections/AboutSection";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function AboutPage() {
   const classes = useStyles();
   return (
      <div>
         <Header
            color="transparent"
            routes={dashboardRoutes}
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
               height: 1000,
               color: "white",
            }}
            checkPageTitleId="page-title"
         />
         <Parallax
            filter
            image={require("assets/img/fabric-start.jpg").default}
            height="60vh"
         >
            <div className={classes.container}>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                     <h1 id="page-title" className={classes.title}>
                        Om oss
                     </h1>
                  </GridItem>
               </GridContainer>
            </div>
         </Parallax>
         <Box sx={{ marginTop: "-20em" }}>
            <div className={classNames(classes.main, classes.mainRaised)}>
               <div className={classes.container}>
                  <Section />
               </div>
            </div>
         </Box>
         <Footer />
      </div>
   );
}
