import { container } from "assets/jss/material-kit-react";

const completedStyle = {
   section: {
      padding: "70px 0",
   },
   container: {
      ...container,
      textAlign: "center !important",
   },
};

export default completedStyle;
