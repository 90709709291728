const ImgPrefix = "data:image/png;base64";
const ImgData = `iVBORw0KGgoAAAANSUhEUgAAAaMAAAFwCAMAAAAi4eRHAAAAAXNSR0IArs4c6QAAAARnQU1BAACx
jwv8YQUAAAAeUExURbm4uKuqqpubm4WEhH59fZCPj3h3d1RTUxQUFAAAACMVTXcAAAAKdFJOU///
/////////wCyzCzPAAAACXBIWXMAAA7CAAAOwgEVKEqAAAAMXklEQVR4Xu2d23bjuA5EfTmenvn/
Hz5xXO44iUzxhkJRq/ZTz6xumcA2QQiyk9N/Rh070seO9LEjfexIHzvSx470sSN97EgfO9LHjvSx
I33sSB870seO9LEjfexIHzvSx470sSN97EgfO9LHjvSxI33sSJ8DOjodLaZjxHO+XP+3yfVyxl9Z
mMUdnc432ChyO68c58JrP11goJLLqrEuuu5WP0+W9LTims9vDp86rsudUMs5Og0JenBdK+rFHFV1
CDXccMEVWMlR5xn0jgsuK886jqZtoS8W2UyrOJpwCm1xxeWlWcLRjD7hHQv0Dws4ijR0R96SvqNg
Q3fELak7CugUtpDuHrQdnZFCAsLTB2lHhDL3hW6LJ+yIVOa+UC14so6IZe4LzYKn6oi+iR5IbiVR
R9ST6BXFU0nSUUqde6JX7xQdJdW5J3L1TtBRWp17olbv5Byl1rknWvVOzdHk53i9SD3/E3MkokhL
kpaj5G7hFaHOQcqRkCIlSUqO0hu678i0d0KOxBTpSNJxhMRIgaUlI+NIbhfd0dhJKo4kFYlIEnEk
1dG9otDdaTiSVSQhScKRzHRhi/yJg4IjaUUCkgQcSUy6S2RPwQUcIRPCYKFZ5DtCHqTBUpNIdyTc
0n2R29xlO5I/jB6kHknZjpADebDcFJIdiY6AfpM5FMp1tMRh9CDxSEp1tMhh9CDvSEp1hOgXAYvm
k+looUp3J63aZTpC7MuAZdNJdLRMT/ckq7fLc7RUw/AgqW3Ic4S4lwJLJ5PmaLGG4UFO25Dl6ISo
FyMlXVmOEPNyYPlUkhwtuo1yNlKSo+X67icZ/XeOo2W3UcpGynG07DZK2UgpjhbeRhkbKcXRwtso
YyOlOEK0i4IgeGQ4WnobJWykDEeIdVkQBo0ER+If796H/QHwBEeIdGEQCAu+o6Ub7wfkpPEdLflQ
4jvkRxR8R4hzaRAKCbqjBR+R/4b70JzuaPGbowfcWyS6I0S5OAiGA9vRIUodudixHSHG5UE4FOyo
D4RDgezoADewD5h5Iztaflb3hDmzIztChAcAATGwo04QEAOuo8McR9QDievoAPPUJ8S5KtcR4jsE
CImAHfWCkAhQHR3oOGIeSFRHBxnWPeCN7KiODtQyMJsGqiNEdxAQVDx21A2CiseOukFQ8dhRNwgq
HqajQ7V1xMaO6egwDyYe0B5PMB0d4iNBX9A+HMR0hNgOA8IKx476QVjh2FE/CCscO+oHYYVjR/0g
rHDsqB+EFY4d9YOwwrGjfhBWOHbUD8IKh+joUE/K77ByZ0f92JE+dqTPAR25Z+jEjvpBWOHYUT8I
Kxw76gdhhWNH/SCscOyoH4QVjh31g7DCsaN+EFY4dtQPwgqH6ehQX5sgfnGC6cifgeyD6cifJe6D
6ehgQ1Va6piODtY0IKh47KgbBBWPHXWDoOKhOjrUFyd4P1OV6uhQzTfvp6NRHR2qseNljuroUAcS
QiJgR70gJAJcR/7ZaD1wHR1oGkT8Cd9cRwcqdgiIgR11goAYkB0d5kBi/goksqPD3CEx80Z2dJhi
h3AosB0dZGRH/QVIbEf+3TrtsB0dpNghGA50R/5db83QHR2i2FFLHd/RIYodQiHBd3SAYsctdQmO
DnAbS04a39EBih0CYZHgaPlPNTB/z9udBEfLbySEQSPD0eJdA7ljyHG0eNdAT1mGo8WLHYLgkeJo
6VkDd8ZwJ8XR0hsJIRDJcbTwiZSQsBxH67Z29KbugyRHy26kjHwlOVp1I2VsozRHi26klHRlOVpz
I6VsozxHS/bfWDqZPEcLjr/ZA2+Q52jBjYSFs0l0tFzbkJWrREerfUCf+TH8b2Q6WqzaYdF8Uh0t
Nf/mz7ufpDpaqdqlVbpsRwtVOyw4g2RHy0jCclPIdrTIkZR3GH2Q7WiNIynxMPog3dES1Q5LTSLf
0QKSsNAsBBzJH0mph9EHAo7UJWUrknCk/Zgi6YHECxKOlJu73JbuEw1Huk/Oc56Of0fEkaokBUUy
jjQlSSjScaQoSUORkCM9SSKKlBypSVJRJOVIqwUXaLqBlCMlSTqKxBzpTBzypwtfiDlSmd2lz+he
UXOk8agCSxFBz1F+eyfT0AFBR9mdg1C38EDRUe6hJHUUfSLpKLHeqdW5O7mO3r96UhP+vuVOTFSq
o2vpbYusMSmsprjUYBIdfbYGhcjpW6lw3/pZe7OaiTRHfw3gvzcgf4mskAr8jaTpQ5Kj184N/2sL
4lYqpR9/5U5G15fj6HvbVloD6V6pVMa+b+eEYynD0a+8F9+chDa8mPdfN2v0Y4nvaOuUKUo6BVu6
FnOwdT9NThrd0XbxKh/GoXOH8gmzfSJytxLZ0dts75T5qL1U3kOFQsvsHbiOSpneW0lA97C3HUrN
P7F3YDraKVm7Nx+TO/HR16NtJaKj3XK1/9Y8Tyt51/0MT1jvHGiOqoYGFauZoalC0LT1ToDlqLJO
VQ1bxjTVCJq73lFIjqqzWlk/zp0dxK3yEJm93iEojprub6rvPVo91fppbCHjM8hw1Pqer87lx/Iv
Ve/466UhztY75vB6R3DUfnq0FpDT+Z2q6+XcGmH8clsJd1TVH/2i/715Op3Pp/6o+u7B8I+DiHbU
PWojbPDf9L2hPmgoz+0Ep2JgNMC6Q3yhvcz9JfJQinU0EPQHewPPuQyObQNH4aFpGIv6A56l8cF6
3L4PTEJ3cX+FY2nOo4+opcaloLtb+EnoeXxHfaVhjqYF/kGoJf2FRjkaaOi2qBuEtjPvYceDkPYu
yNFkRXdu05d6Cni0GyEpxlFA8HemJiDgbXQnoAcPcRSk6M6kmjfzEPrBfEkRjibX+J+Mnk2zz6Cf
TL9RCnAUnIJP6p8Ffaf32WATsyXNd8RQ9Mmt7bHDieLnk8mSpjuiKQLXy+6Wevt0KYy5kmY7Ymfj
yfV2u1y+nhzdnyJdLrdb2nIey5jDZEe0cqLOzO5uriMr+stESVMdWdEL8yTNdBR0574q06YiEx1Z
0Q9mSZrnKHC8siqTBlfTHE156no05mR3miOsynwDyRljliOsyfwA6RlikqOs+3l5Zgwc5jjyjdFb
JtwmTXHkrrvAeAc+w5FbuiLDKZ7hCGsxb0CaupngyP3CDqN9w7gjrX7hnz//4E9CDPYNw47ERkB/
/v2DPykxNhQadoRVqCC5jwaPpFFHPoyqGDqSBh355rWSkSNpzJGfR1QzcCSNOcLrmwqQsg6GHPkw
aqD/SBpx5DFdE93VbsQRXttUgrQ1M+DIla6R3mrX78g9XTOd1a7fEV7XNIDUNdLtyJWug75q1+vI
la6LrmrX6wivaRpB+prodOQ5XSc9c7s+R/4EQzcdCe9z5Iahm462ocuRG4YB2tuGLkd4NdMFklhP
jyM3DEM0tw09jvBaphOksZoOR24YBmltG9odue8epjHp7Y7wOmYApLKSZkfuuyfQ1n83O8KrmCGQ
zDpaHXkbTaFpI7U6wmuYQZDOKhodeRtNomUjNTrCK5hhkNAa2hx5G02jYSO1OcL1zQSQ0gqaHHkb
TaR+IzU5wtXNFJDUfVoceVI3lerUtzjywHsq1ePvFke4tpkE0rpLgyM/fp1M7QPZBke4spkGErtH
vSM33tOpbL/rHeG6ZiJI7Q7Vjtx4B1CX/WpHbrwDqGu/qx3hqmYqSG6ZWkf+DnkIVT8kstYRrmkm
g/QWsaNckN4ilY48YwiiZtZQ6QhXNNNBgkvUOfLNURgVAuocudSFUVHs6hzheiYApLhAlSOPUwPZ
H6xWOfIcKJD9eVCVI1zNhIAkv6fGkbu6UHYV1DhyVxfKbmdX4wjXMkEgzW+xo3yQ5rdUOPJjiWD2
HlBUOMKVTBhI9DvsSAAk+h37jjxkCGdn1LDvyEOGcHZGDfuOcB0TCFL9BjtSAKl+w64jH0cEygfS
riMPggiUx0G7jnAVEwqSvY0dSYBkb7PnyM8lKBQ17DnysI5CcWS35wjXMMEg3ZvYkQZI9yZ2pAHS
vcmOI9/Bkijdxe448h0sidJd7I4jXMGEg4RvYUciIOFb2JEISPgWdiQCEr5F2ZHbOhqFxq7syJMg
GoVpUNmRP8tAo/CZhrIj/HtDACnfwI5UQMo3sCMVkPINio78gI/IexN2pIId6dPpyOcREaR8AztS
ASnfoOzI97A0uu9hPa+j0T2vc7GjgYRvsePInR2JkocdR558cxj6DKQlMSh/s3zXkSXFs/Pl/31H
7sCD2fk27H///R9co0ha+kYVWgAAAABJRU5ErkJggg==`;
export const DefaultImage = `${ImgPrefix},${ImgData}`;
