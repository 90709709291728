export const isAdmin = (user) => {
   const adminRole = user.context.roles.find((r) => r.key === "Admin");
   return adminRole ? true : false;
};

export const isReadOnly = (user) => {
   const role = user.context.roles.find((r) => r.key === "ReadOnly");
   return role ? true : false;
};

export const isNewUser = (user) => {
   const role = user.context.roles.find((r) => r.key === "NewUser");
   return role ? true : false;
};

export const isConsultantBasic = (user) => {
   const role = user.context.roles.find((r) => r.key === "ConsultantBasic");
   return role ? true : false;
};
