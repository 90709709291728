export const texConLocalizations = {
   sv: {
      loginBox: {
         login: {
            title: "Logga in",
         },
      },
      signup: {
         /**
          * Sign up page title
          */
         // title: string;
         /**
          * Signup name input label
          */
         // nameInputLabel: string;
         /**
          * Signup name input placeholder
          */
         // nameInputPlaceholder: string;
         /**
          * error message displayed if name is empty
          */
         // nameIsRequired: string;
         /**
          * error message displayed if name length less than 3
          */
         // nameMinLengthIs3: string;
         /**
          * Signup email input label
          */
         // emailInputLabel: string;
         /**
          * Signup email input placeholder
          */
         // emailInputPlaceholder: string;
         /**
          * error message displayed if email is invalid
          */
         // emailMustBeValid: string;
         /**
          * error message displayed if email is empty
          */
         // emailIsRequired: string;
         /**
          * Signup password input label
          */
         // passwordInputLabel: string;
         /**
          * Signup password input placeholder
          */
         // passwordInputPlaceholder: string;
         /**
          * error message displayed if password is empty
          */
         // passwordIsRequired: string;
         /**
          * Signup company name input label
          * this input is optional
          */
         // companyNameInputLabel: string;
         /**
          * Signup company name input placeholder
          * this input is optional
          */
         // companyNameInputPlaceholder: string;
         /**
          * error message displayed if name is empty
          */
         // companyNameIsRequired: string;
         /**
          * error message displayed if name length less than 3
          */
         // companyNameMinLengthIs3: string;
         /**
          * String displayed as separator for sign up with social login
          */
         // signUpWithSocialLogin: string;
         /**
          * Go to login message in signup page header
          */
         // loginMessage: string;
         /**
          * Go to login link button text in signup page header
          */
         // loginLink: string;
         /**
          * Signup page submit button
          */
         // signupButtonText: string;
         /**
          * Text displayed when join tenant invitation failed
          */
         // failedJoinTenantTitle: string;
         /**
          * Back to login button text join tenant invitation failed
          */
         // failedJoinTenantBackButton: string;
         /**
          * Success title when signup succeeded
          */
         // successTitle: string;
         /**
          * Activate description message when signup succeeded and activate required
          */
         // successActivateMessage: string;
         /**
          * Redirect to login
          */
         // backToLoginLink: string;
         /**
          * Go to login message in signup page header
          */
         // successGoToLoginMessage: string;
         /**
          * Go to login link button text in Signup page header
          */
         // successGoToLoginButton: string;
      },
   },
   en: {
      loginBox: {
         login: {
            title: "Logga in",
            /**
             * Go to signup message in Login page header
             */
            signUpMessage: "Vill du använda TexCon? ",
            /**
             * Go to signup link button text in Login page header
             */
            signUpLink: "Skapa ett konto!",
            /**
             * Login page submit button
             */
            login: "Fortsätt",
            /**
             * Login page submit button if passwordless, sso or magicLink is enabled
             */
            continue: "Logga på ->",
            /**
             * String displayed as placeholder when email field is empty
             */
            emailInputPlaceholder: "Din registrerade emailadress",
            emailInputLabel: "E-mail",
            /**
             * Forgot password label
             */
            forgotPassword: "Glömt ditt lösenord?",

            /**
             * String displayed as placeholder when password field is empty
             */
            passwordInputPlaceholder: "Ange ditt lösenord",
            passwordInputLabel: "Lösenord",
            /**
             * Recover Code input placeholder in MFA recovery section
             */
            enterRecoveryCode: "Återställningskod",
            /**
             * Otc input placeholder
             */
            otcInputPlaceholder: "OTC",
            /**
             * Force MFA section input placeholder
             */
            forceMfaInputPlaceholder: "MFA-kod",
         },
         signup: {
            /**
             * Sign up page title
             */
            title: "Skapa konto",
            /**
             * Signup name input label
             */
            nameInputLabel: "Namn",
            /**
             * Signup name input placeholder
             */
            nameInputPlaceholder: "Ange namn",
            /**
             * error message displayed if name is empty
             */
            nameIsRequired: "Namn är obligatoriskt",
            /**
             * error message displayed if name length less than 3
             */
            nameMinLengthIs3: "Namn måste innehålla mer än tre tecken",
            /**
             * Signup email input label
             */
            emailInputLabel: "E-mailadress",
            /**
             * Signup email input placeholder
             */
            emailInputPlaceholder: "namn@domain.se",
            /**
             * error message displayed if email is invalid
             */
            emailMustBeValid: "Ogiltlig e-mailadress",
            /**
             * error message displayed if email is empty
             */
            emailIsRequired: "E-mailadress är obligatoriskt",
            /**
             * Signup password input label
             */
            passwordInputLabel: "Lösenord",
            /**
             * Signup password input placeholder
             */
            passwordInputPlaceholder: "Ange lösenord",
            /**
             * error message displayed if password is empty
             */
            passwordIsRequired: "Du måste ange ett lösenord",
            /**
             * Signup company name input label
             * this input is optional
             */
            companyNameInputLabel: "Företagsnamn (ej obligatoriskt)",
            /**
             * Signup company name input placeholder
             * this input is optional
             */
            companyNameInputPlaceholder: "Företaget AB",
            /**
             * error message displayed if name is empty
             */
            companyNameIsRequired: "Ange företagsnamn",
            /**
             * error message displayed if name length less than 3
             */
            companyNameMinLengthIs3: "",
            /**
             * String displayed as separator for sign up with social login
             */
            signUpWithSocialLogin: "",
            /**
             * Go to login message in signup page header
             */
            loginMessage: "Har du konto? ",
            /**
             * Go to login link button text in signup page header
             */
            loginLink: "Logga in här",
            /**
             * Signup page submit button
             */
            signupButtonText: "Skapa konto",
            /**
             * Text displayed when join tenant invitation failed
             */
            failedJoinTenantTitle: "",
            /**
             * Back to login button text join tenant invitation failed
             */
            failedJoinTenantBackButton: "",
            /**
             * Success title when signup succeeded
             */
            successTitle: "Konto skapat",
            /**
             * Activate description message when signup succeeded and activate required
             */
            successActivateMessage:
               "Konto skapat, se din epost för att aktivera kontot.",
            /**
             * Redirect to login
             */
            backToLoginLink: "Loginsida",
            /**
             * Go to login message in signup page header
             */
            successGoToLoginMessage: "Konto skapat, logga på här",
            /**
             * Go to login link button text in Signup page header
             */
            successGoToLoginButton: "Logga på nya kontot",
         },
      },
   },
};
