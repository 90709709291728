import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/material-kit-react/components/parallaxStyle.js";

export default function Parallax(props) {
   if (props.height) {
      styles.parallax.height = props.height;
   }
   const useStyles = makeStyles(styles);

   let windowScrollTop;
   if (window.innerWidth >= 768) {
      windowScrollTop = window.pageYOffset / 3;
   } else {
      windowScrollTop = 0;
   }
   const [transform, setTransform] = React.useState(
      "translate3d(0," + windowScrollTop + "px,0)"
   );
   React.useEffect(() => {
      if (window.innerWidth >= 768) {
         window.addEventListener("scroll", resetTransform);
      }
      return function cleanup() {
         if (window.innerWidth >= 768) {
            window.removeEventListener("scroll", resetTransform);
         }
      };
   });
   const resetTransform = () => {
      var windowScrollTop = window.pageYOffset / 3;
      setTransform("translate3d(0," + windowScrollTop + "px,0)");
   };
   const { filter, className, children, style, image, small } = props;
   const classes = useStyles();
   const parallaxClasses = classNames({
      [classes.parallax]: true,
      [classes.filter]: filter,
      [classes.small]: small,
      [className]: className !== undefined,
   });
   return (
      <div
         className={parallaxClasses}
         style={{
            ...style,
            backgroundImage: "url(" + image + ")",
            transform: transform,
         }}
      >
         {children}
      </div>
   );
}

Parallax.propTypes = {
   className: PropTypes.string,
   filter: PropTypes.bool,
   children: PropTypes.node,
   style: PropTypes.string,
   image: PropTypes.string,
   small: PropTypes.bool,
   height: PropTypes.string,
};
