import { container } from "assets/jss/material-kit-react.js";

const carouselStyle = {
   section: {
      padding: "70px 0",
   },
   container,
   marginAuto: {
      marginLeft: "auto !important",
      marginRight: "auto !important",
   },
};

export default carouselStyle;
