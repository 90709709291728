import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/sectionStyle.js";

const useStyles = makeStyles(styles);

export default function StartSection() {
   const classes = useStyles();
   return (
      <div className={classes.section}>
         <GridContainer justifyContent="center">
            <GridItem cs={12} sm={12} md={8}>
               <h2 className={classes.title}>Texia</h2>
               <h4 className={classes.description}>
                  <p>
                     Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                     sed do eiusmod tempor incididunt ut labore et dolore magna
                     aliqua. Lectus arcu bibendum at varius vel pharetra vel.
                     Quis lectus nulla at volutpat diam ut venenatis tellus in.
                  </p>
                  <p>
                     Et netus et malesuada fames ac turpis egestas maecenas. Id
                     leo in vitae turpis massa sed elementum. Sit amet facilisis
                     magna etiam tempor orci. Id ornare arcu odio ut sem nulla
                     pharetra. Tincidunt praesent semper feugiat nibh. Amet
                     tellus cras adipiscing enim. Fermentum posuere urna nec
                     tincidunt praesent semper feugiat nibh sed. Varius quam
                     quisque id diam vel. Ultricies integer quis auctor elit sed
                     vulputate.
                  </p>
               </h4>
            </GridItem>
         </GridContainer>
      </div>
   );
}
