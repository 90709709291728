import { title } from "assets/jss/material-kit-react.js";

const sectionStyle = {
   section: {
      padding: "70px 0",
   },
   title: {
      ...title,
      marginBottom: "50px",
      marginTop: "30px",
      minHeight: "32px",
      textDecoration: "none",
      textAlign: "center",
   },
   description: {
      color: "#999",
      textAlign: "center",
   },
   descriptionLeft: {
      color: "#999",
      textAlign: "left",
   },
   textCenter: {
      textAlign: "center",
   },
   textArea: {
      marginRight: "15px",
      marginLeft: "15px",
   },
};

export default sectionStyle;
