// import React from "react";
// import { Redirect } from "react-router";
// import PropTypes from "prop-types";

// export const ProtectedRoute = (props) => {
//   const Component = props.component;
//   const isAuthenticated = true;

//   return isAuthenticated ? <Component /> : <Redirect to={{ pathname: "/" }} />;
// };

// ProtectedRoute.propTypes = {
//   path: PropTypes.string,
//   component: PropTypes.func,
// };

// export default ProtectedRoute;

import React from "react";
import { Route, Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { UserContext } from "app";

const ProtectedRoute = ({ role, children }) => {
   const user = React.useContext(UserContext);
   console.log("checking role => ", role);
   if (!user.isUserAuthenticated) {
      console.log("location.from: " + location.pathname);
      return (
         <Navigate
            to="/start"
            state={{ location: location.pathname }}
            replace={true}
         />
      );
   }
   if (!role || isUserInRole(role, user.context.roles)) {
      return children;
   } else {
      console.log(`Not authorized [${role}].`);
      return null;
   }
};

const isUserInRole = (rolesList, userRoles) => {
   let found = false;
   rolesList.forEach((roleName) => {
      if (userRoles.find((r) => r.key === roleName)) {
         found = true;
      }
   });
   return found;
};

function ProtectedRoute2({ children, ...rest }) {
   const userContext = React.useContext(UserContext);
   return (
      <>
         <Route
            {...rest}
            render={({ location }) => {
               console.log(
                  "Route Render => UserContext, auth:" +
                     userContext?.isUserAuthenticated
               );
               console.log("Locataion:");
               console.log(location);
               return userContext.isUserAuthenticated ? (
                  children
               ) : (
                  <Navigate
                     to={{ pathname: "/login-page", state: { from: location } }}
                  />
               );
            }}
         />
      </>
   );
}

ProtectedRoute2.propTypes = {
   children: PropTypes.any,
   component: PropTypes.any,
};

export default ProtectedRoute;
