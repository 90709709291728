import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { UserContext } from "app";
import { Navigate } from "react-router-dom";

// Sections for this page
import TeamSection from "./Sections/TeamSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function AllProfiles(props) {
   const classes = useStyles();
   const { ...rest } = props;
   const user = React.useContext(UserContext);

   if (!user.isUserAuthenticated) {
      return <Navigate to="/start" />;
   }

   return (
      <div>
         <Header
            color="transparent"
            routes={dashboardRoutes}
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
               height: 300,
               color: "white",
            }}
            checkPageTitleId="the-page-title"
            {...rest}
         />
         <Parallax
            filter
            image={require("assets/img/fabric-profiles.jpg").default}
            height="50vh"
         >
            <div className={classes.container}>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                     <h1 id="the-page-title" className={classes.title}>
                        Alla våra profiler.
                     </h1>
                     <h4>
                        Här är de profiler som är tillgängliga just nu. Klicka
                        på profilen för mer information. Kontakta oss om mer
                        information önskas.
                     </h4>
                  </GridItem>
               </GridContainer>
            </div>
         </Parallax>
         <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.container}>
               <TeamSection />
            </div>
         </div>
         <Footer />
      </div>
   );
}
