import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import EditSection from "./Sections/EditSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function EditProfilesPage(props) {
   const classes = useStyles();
   const { ...rest } = props;
   return (
      <div>
         <Header
            color="transparent"
            routes={dashboardRoutes}
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
               height: 300,
               color: "white",
            }}
            checkPageTitleId="page-title"
            {...rest}
         />
         <Parallax
            filter
            image={require("assets/img/fabric-start.jpg").default}
            height="30vh"
         >
            <div className={classes.container}>
               <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                     <h1 id="page-title" className={classes.title}>
                        Redigera profiler.
                     </h1>
                  </GridItem>
               </GridContainer>
            </div>
         </Parallax>
         <div className={classNames(classes.main, classes.mainRaised)}>
            <div className={classes.container}>
               <EditSection />
            </div>
         </div>
         <Footer />
      </div>
   );
}
