import React from "react";
import PropTypes from "prop-types";
import { FileUploader } from "react-drag-drop-files";
import "./DragDrop.css";

const fileTypes = ["JPG", "PNG", "GIF"];

function DragDrop(props) {
   const { setSelectedFileHandler } = props;
   // const [imageFile, setImageFile] = useState(null);

   const handleChange = (file) => {
      // setImageFile(file);
      imageFileToBase64(file);
   };

   const imageFileToBase64 = (file) => {
      const reader = new window.FileReader();
      if (file) {
         reader.readAsDataURL(file);
         reader.onloadend = () => {
            const base64data = reader.result;
            // console.log("base64:");
            // console.log(base64data)
            setSelectedFileHandler(file, base64data);
            changeSuccessText();
         };
      }
   };

   function findElementContains(selector, text) {
      var elements = document.querySelectorAll(selector);
      return Array.prototype.filter.call(elements, function (element) {
         return RegExp(text).test(element.textContent);
      });
   }

   const handleTypeError = (err) => {
      console.log(err);
      setTimeout(setTypeErrorText, 50); //vänta på dom uppdaterats
   };

   const setTypeErrorText = () => {
      let typeElement = document
         .querySelector(".sc-dkPtRN")
         ?.querySelector("span");
      if (typeElement) {
         typeElement.innerText = "Felaktig bildtyp!";
      }
   };

   const handleSizeError = (err) => {
      console.log(err);
      setTimeout(setSizeErrorText, 50); //vänta på dom uppdaterats
   };

   const setSizeErrorText = () => {
      // let typeElement = findElementContains("span", "File type/size error")[0];
      let typeElement = document
         .querySelector(".sc-dkPtRN")
         ?.querySelector("span");
      if (typeElement) {
         typeElement.textContent = "Bilden är för stor (max 1mb)!";
      }
   };

   const changeSuccessText = () => {
      let typeElement = findElementContains("span", "Uploaded Successfully");
      if (typeElement[0]) {
         typeElement[0].innerText = "Bild vald. Klicka/droppa för att byta. ";
      }
   };

   return (
      <FileUploader
         handleChange={handleChange}
         id="hans"
         name="imageUpLoader"
         types={fileTypes}
         label=" Ladda upp profilbild här"
         hoverTitle="Drag och släpp bild här"
         onTypeError={(err) => handleTypeError(err)}
         onSizeError={(err) => handleSizeError(err)}
         maxSize={1}
      />
   );
}

DragDrop.propTypes = {
   setSelectedFileHandler: PropTypes.func,
};

export default DragDrop;
