import React from "react";
import ReactDOM from "react-dom";

import App from "./app";
import { FronteggProvider } from "@frontegg/react";
import { texConLocalizations } from "./assets/localizations";

const contextOptions = {
   baseUrl: "https://texia-prod.frontegg.com",
   clientId: "b234f711-180e-46cb-a221-ff908fcddb8f",
};

const themeOptions = {
   loginBox: {
      logo: {
         // image: "https://assets.frontegg.com/public-frontegg-assets/acme-logo.svg",
      },
   },
};

ReactDOM.render(
   <FronteggProvider
      contextOptions={contextOptions}
      themeOptions={themeOptions}
      localizations={texConLocalizations}
   >
      <React.StrictMode>
         <App />
      </React.StrictMode>
   </FronteggProvider>,
   document.getElementById("root")
);
