import React, { useState, useEffect } from "react";
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { ProfileCard } from "components/ProfileCard";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/teamStyle.js";
import { fetchAllProfiles } from "api/Profiles";
import { UserContext } from "app";

const useStyles = makeStyles(styles);

export default function TeamSection() {
   const classes = useStyles();
   const [userData, setUserData] = useState([]);
   const user = React.useContext(UserContext);

   useEffect(() => {
      // console.log("hämtar data...");
      fetchAllProfiles(user.context.accessToken, setUserData);
   }, []);

   return (
      <div className={classes.section}>
         <h2 className={classes.title}>Här är alla profiler</h2>
         <div>
            <GridContainer>
               {userData &&
                  userData.map((profile) => (
                     <GridItem xs={12} sm={12} md={4} key={profile.user_id}>
                        <ProfileCard profile={profile} />
                     </GridItem>
                  ))}
            </GridContainer>
         </div>
      </div>
   );
}
