import React, { useRef } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import { BrandContext } from "app";
import { useNavigate } from "react-router";

const useStyles = makeStyles(styles);
const HEADER_OFFSET = 10;

export default function Header(props) {
   const { displayLinks, checkPageTitleId } = props;
   const brand = React.useContext(BrandContext);
   const history = useNavigate();

   const classes = useStyles();
   const [mobileOpen, setMobileOpen] = React.useState(false);
   const headerPosRef = useRef(0);

   React.useEffect(() => {
      if (headerPosRef.current === 0) {
         const headerArea = document.getElementById("header-area");
         headerPosRef.current = headerArea
            .getBoundingClientRect()
            .bottom.toFixed(0);
         // console.log("sätter ref:", headerPosRef.current);
      }
      if (props.changeColorOnScroll) {
         window.addEventListener("scroll", headerColorChange);
         window.addEventListener("resize", headerColorChange);
      }
      return function cleanup() {
         if (props.changeColorOnScroll) {
            window.removeEventListener("scroll", headerColorChange);
            window.removeEventListener("resize", headerColorChange);
         }
      };
   }, []);

   const getTitleDataObject = () => {
      const updatedPageTitle = document.getElementById(checkPageTitleId);
      if (updatedPageTitle) {
         const titleStyles = getComputedStyle(updatedPageTitle);
         const emptySpace =
            // eslint-disable-next-line no-useless-escape
            parseInt(titleStyles.marginTop.replace(/[^0-9\.]+/g, "")) +
            // eslint-disable-next-line no-useless-escape
            parseInt(titleStyles.paddingTop.replace(/[^0-9\.]+/g, ""));
         return {
            boundingBox: updatedPageTitle.getBoundingClientRect(),
            emptySpaceTop: emptySpace,
         };
      } else {
         console.log("No checkPageTitleId in prop to Header.js");
      }
      return null;
   };

   const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
   };

   const headerColorChange = () => {
      const titlePosData = getTitleDataObject();
      const titlePos =
         (titlePosData.boundingBox ? titlePosData.boundingBox.top : 0) +
         titlePosData.emptySpaceTop +
         HEADER_OFFSET;

      const { color, changeColorOnScroll } = props;
      const windowsScrollTop = window.pageYOffset;
      const headerPosBottom = headerPosRef.current;
      // console.log(
      //   `titlepos / headerpos: ${titlePos.toFixed(0)} / ${headerPosBottom}`
      // );

      if (
         windowsScrollTop > changeColorOnScroll.height ||
         titlePos < headerPosBottom
      ) {
         // console.log(`krock...`);
         document.body
            .getElementsByTagName("header")[0]
            .classList.remove(classes[color]);
         document.body
            .getElementsByTagName("header")[0]
            .classList.add(classes[changeColorOnScroll.color]);
      } else {
         document.body
            .getElementsByTagName("header")[0]
            .classList.add(classes[color]);
         document.body
            .getElementsByTagName("header")[0]
            .classList.remove(classes[changeColorOnScroll.color]);
      }
   };
   const { color, rightLinks, leftLinks, fixed, absolute } = props;
   const appBarClasses = classNames({
      [classes.appBar]: true,
      [classes[color]]: color,
      [classes.absolute]: absolute,
      [classes.fixed]: fixed,
   });
   const brandComponent = <Button className={classes.title}>{brand}</Button>;
   return (
      displayLinks && (
         <AppBar id="header-area" className={appBarClasses}>
            <Toolbar className={classes.container}>
               {leftLinks !== undefined ? brandComponent : null}
               <div className={classes.flex}>
                  {leftLinks !== undefined ? (
                     <Hidden smDown implementation="css">
                        {leftLinks}
                     </Hidden>
                  ) : (
                     <span onClick={() => history("/")}>{brandComponent}</span>
                  )}
               </div>
               <Hidden smDown implementation="css">
                  {rightLinks}
               </Hidden>
               <Hidden mdUp>
                  <IconButton
                     color="inherit"
                     aria-label="open drawer"
                     onClick={handleDrawerToggle}
                  >
                     <Menu />
                  </IconButton>
               </Hidden>
            </Toolbar>
            <Hidden mdUp implementation="js">
               <Drawer
                  variant="temporary"
                  anchor={"right"}
                  open={mobileOpen}
                  classes={{
                     paper: classes.drawerPaper,
                  }}
                  onClose={handleDrawerToggle}
               >
                  <div className={classes.appResponsive}>
                     {leftLinks}
                     {rightLinks}
                  </div>
               </Drawer>
            </Hidden>
         </AppBar>
      )
   );
}

Header.defaultProp = {
   color: "white",
};

Header.propTypes = {
   color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark",
   ]),
   rightLinks: PropTypes.node,
   leftLinks: PropTypes.node,
   brand: PropTypes.string,
   fixed: PropTypes.bool,
   absolute: PropTypes.bool,
   // this will cause the sidebar to change the color from
   // props.color (see above) to changeColorOnScroll.color
   // when the window.pageYOffset is heigher or equal to
   // changeColorOnScroll.height and then when it is smaller than
   // changeColorOnScroll.height change it back to
   // props.color (see above)
   changeColorOnScroll: PropTypes.shape({
      height: PropTypes.number.isRequired,
      color: PropTypes.oneOf([
         "primary",
         "info",
         "success",
         "warning",
         "danger",
         "transparent",
         "white",
         "rose",
         "dark",
      ]).isRequired,
   }),
   displayLinks: PropTypes.bool,
   checkPageTitleId: PropTypes.string.isRequired,
};

Header.defaultProps = {
   displayLinks: true,
};
