import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import styles from "assets/jss/material-kit-react/views/teamStyle.js";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles(styles);

export const ProfileCard = (props) => {
   const classes = useStyles();
   const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
   );

   const { profile } = props;

   const navigate = useNavigate();

   // React.useEffect(() => {
   //    console.log("profileCard props");
   //    console.log(props);
   // }, []);

   const ProfileClick = () => {
      let navState = { state: {} };
      navState.state.id = 7;
      navState.state.profile = props.profile;
      navigate("/profile-page", { state: { id: 7, profile: profile } });
   };

   return (
      <Card plain onClick={ProfileClick}>
         <GridItem
            xs={12}
            sm={12}
            md={6}
            className={`${classes.itemGrid} ${classes.cursorPointer}`}
            onClick={ProfileClick}
         >
            <img
               src={profile.alternative_image}
               alt="..."
               className={imageClasses}
            />
         </GridItem>
         <h4
            className={`${classes.cardTitle} ${classes.cursorPointer}`}
            onClick={ProfileClick}
         >
            {props.name}
            <br />
            <small className={classes.smallTitle}>{profile.type}</small>
         </h4>
         <CardBody>
            <p className={classes.description}>{profile.description}</p>
         </CardBody>
         <CardFooter className={classes.justifyCenter}>
            {profile.twitter && (
               <Button justIcon color="transparent" className={classes.margin5}>
                  <a href={profile.twitter} target="_blank" rel="noreferrer">
                     <i className={classes.socials + " fab fa-twitter"} />
                  </a>
               </Button>
            )}
            {profile.instagram && (
               <a href={profile.instagram} target="_blank" rel="noreferrer">
                  <Button
                     justIcon
                     color="transparent"
                     className={classes.margin5}
                  >
                     <i className={classes.socials + " fab fa-instagram"} />
                  </Button>
               </a>
            )}
            {profile.facebook && (
               <Button justIcon color="transparent" className={classes.margin5}>
                  <a href={profile.facebook} target="_blank" rel="noreferrer">
                     <i className={classes.socials + " fab fa-facebook"} />
                  </a>
               </Button>
            )}
            {profile.linkedin && (
               <Button justIcon color="transparent" className={classes.margin5}>
                  <a href={profile.linkedin} target="_blank" rel="noreferrer">
                     <i className={classes.socials + " fab fa-linkedin"} />
                  </a>
               </Button>
            )}
         </CardFooter>
      </Card>
   );
};

ProfileCard.propTypes = {
   name: PropTypes.string,
   image: PropTypes.string,
   description: PropTypes.string,
   type: PropTypes.string,
   linkedin: PropTypes.string,
   facebook: PropTypes.string,
   twitter: PropTypes.string,
   instagram: PropTypes.string,
   profile: PropTypes.any,
};
