import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInput";

import Grid from "@mui/material/Grid";
import Button from "components/CustomButtons/Button.js";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupAdd from "@mui/icons-material/GroupAdd";
import { DefaultImage } from "./ImageData";

import styles from "assets/jss/material-kit-react/views/landingPage.js";
import DragDrop from "components/DragDrop/DragDrop";
import {
   fetchAllProfiles,
   fetchUserProfile,
   saveNewProfilePromise,
   updateProfilePromise,
   deleteProfilePromise,
} from "api/Profiles";
import { isAdmin, isConsultantBasic } from "core/UserFunctions";
import { UserContext } from "app";
import { Tooltip } from "@mui/material";
import CustomConfirm from "components/CustomConfirm/customConfirm";

const useStyles = makeStyles(styles);

export default function EditSection() {
   const user = useContext(UserContext);

   const classes = useStyles();

   const createNewProfileText = "Skapa ny profil";
   const emptyProfile = {
      email: "",
      name: "",
      type: "",
      description: "",
      moreinfo: "",
      image: "",
      alternative_image: DefaultImage,
      linkedin: "",
      facebook: "",
      twitter: "",
      instagram: "",
   };

   const [editProfile, setEditProfile] = useState(emptyProfile);
   const [allProfiles, setAllProfiles] = useState([]);
   const [userProfile, setUserProfile] = useState(null);
   const [selectedProfile, setSelectedProfile] = useState(emptyProfile);
   const [snackMessage, setSnackMessage] = useState("");
   const [showConfirmDelete, setShowConfirmDelete] = useState(true);

   const jwt = user.context.accessToken;
   let scrollTopRef = React.useRef();

   useEffect(() => {
      if (isAdmin(user)) {
         fetchAllProfiles(jwt, setAllProfiles);
      }
      if (isConsultantBasic(user)) {
         fetchUserProfile(jwt, user.context?.email, setUserProfile);
      }
      if (isConsultantBasic(user)) {
         setEditProfile({
            ...editProfile,
            email: user.context.email,
         });
      }
   }, []);

   useEffect(() => {
      if (userProfile) {
         setSelectedProfile(userProfile);
         setEditProfile(userProfile);
         console.log("userprofile changed: ", userProfile);
      }
      setShowConfirmDelete(false);
   }, [userProfile]);

   const deleteProfile = () => {
      deleteProfilePromise(jwt, selectedProfile)
         .then((result) => {
            console.log("Raderat: ", result);
            setSnackMessage(`Raderat ${selectedProfile.name}`);
            let newProfilesList = removeSelectedFromProfiles();
            setAllProfiles(newProfilesList);
            setEditProfile(emptyProfile);
            setSelectedProfile(emptyProfile);
         })
         .catch((error) => {
            console.log("Kunde inte radera: ", error);
         });
   };

   const handleConfirmDeleteProfile = () => {
      deleteProfile();
      setShowConfirmDelete(false);
   };

   const removeSelectedFromProfiles = () => {
      const foundIndex = allProfiles.findIndex(
         (p) => p.name === selectedProfile.name
      );
      var copyOfProfiles = [...allProfiles];
      copyOfProfiles.splice(foundIndex, 1);
      return copyOfProfiles;
   };

   const handleProfileChange = (e) => {
      console.log("Profilbyte: ", e.target);
      const profile = allProfiles.find((i) => i.name === e.target.value);
      if (e.target.value === createNewProfileText) {
         setSelectedProfile(emptyProfile);
         setEditProfile(emptyProfile);
      } else {
         console.log(profile);
         setSelectedProfile(profile);
         setEditProfile(profile);
      }
   };

   const handleSaveNewProfile = () => {
      console.log("sparar ny profil...", editProfile);
      const existingProfile = allProfiles.find(
         (i) => i.email === editProfile.email
      );
      if (existingProfile) {
         setSnackMessage("Profil finns redan. ", existingProfile);
         return;
      }
      saveNewProfilePromise(jwt, editProfile)
         .then((result) => {
            if (result.data.result === false) {
               console.log("Promise ok men resultat false:", result);
            }
            setSnackMessage(`Lagt till ${editProfile.name}`);
            if (isAdmin(user)) {
               fetchAllProfiles(jwt, setAllProfiles);
               setSelectedProfile(editProfile);
               window.scrollTo({
                  behavior: "smooth",
                  top: scrollTopRef.current.offsetTop,
               });
            }
         })
         .catch((error) => {
            console.log("Fel vid spara ny profil!", error);
            setSnackMessage(
               `Kunde inte spara ${editProfile.name}! [${error.data.error}]`
            );
         });
   };

   const handleUpdateProfile = () => {
      if (!editProfile) {
         return;
      }
      let newProfileList = removeSelectedFromProfiles();
      updateProfilePromise(jwt, editProfile)
         .then((result) => {
            console.log("Uppdaterat: ", result);
            newProfileList = [editProfile, ...newProfileList];
            setAllProfiles(newProfileList);
            setSnackMessage(`Uppdaterat ${editProfile.name}`);
            setSelectedProfile(editProfile);
         })
         .catch((error) => {
            console.log("Uppdatera gick fel: ", error);
         });
   };

   const Alert = React.forwardRef(function Alert(props, ref) {
      return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
   });

   const handleSnackClose = (event, reason) => {
      if (reason === "clickaway") {
         return;
      }
      setSnackMessage("");
   };

   const isProfileEqual = (obj1, obj2) =>
      JSON.stringify(obj1) === JSON.stringify(obj2);

   const isSelectedEmtpy = () =>
      JSON.stringify(selectedProfile) === JSON.stringify(emptyProfile);

   const handleProfileImageChange = (file, fileData) => {
      setEditProfile({
         ...editProfile,
         alternative_image: fileData,
      });
   };

   return (
      <div ref={scrollTopRef} className={classes.section}>
         <GridContainer justifyContent="center">
            <GridItem>
               <FormControl
                  variant="standard"
                  sx={{ m: 5, ml: 0, minWidth: 120 }}
               >
                  {isAdmin(user) && (
                     <>
                        <InputLabel id="selected-profile-label">
                           Välj profil
                        </InputLabel>
                        <Select
                           sx={{
                              bgcolor: "#EEE",
                              pt: 1,
                              px: 0.5,
                              minWidth: "10em",
                           }}
                           labelId="selected-profile-label"
                           id="selected-profile"
                           onChange={handleProfileChange}
                           label="Välj profil"
                           value={
                              selectedProfile?.name === ""
                                 ? createNewProfileText
                                 : selectedProfile.name
                           }
                        >
                           <MenuItem key={0} value={createNewProfileText}>
                              {createNewProfileText}
                           </MenuItem>
                           {allProfiles.map((profile, idx) => (
                              <MenuItem key={idx + 1} value={profile.name}>
                                 {profile.name}
                              </MenuItem>
                           ))}
                        </Select>
                     </>
                  )}
               </FormControl>
            </GridItem>
            <GridItem cs={12} sm={12} md={8}>
               <form>
                  <GridContainer justifyContent="center" alignItems="flex-end">
                     <GridItem xs={4} sm={3} md={3}>
                        <img
                           className="profileImage"
                           src={editProfile.alternative_image}
                           style={{
                              width: "100%",
                              maxWidth: "100%",
                           }}
                        />
                     </GridItem>
                     <GridItem xs={8} sm={9} md={9}>
                        <DragDrop
                           setSelectedFileHandler={handleProfileImageChange}
                        />
                     </GridItem>
                     <GridItem xs={12}>
                        <div>
                           <br />
                        </div>
                     </GridItem>
                     <GridItem xs={12} sm={12} md={12}>
                        <Tooltip
                           title={
                              isConsultantBasic(user)
                                 ? "Kan inte ändras, används som id för din profil."
                                 : ""
                           }
                           placement="top-start"
                        >
                           <span>
                              <CustomInput
                                 labelText="E-mail"
                                 id="email"
                                 formControlProps={{
                                    fullWidth: true,
                                 }}
                                 inputProps={{
                                    onChange: (e) => {
                                       setEditProfile({
                                          ...editProfile,
                                          email: e.target.value,
                                       });
                                    },
                                    value: editProfile.email,
                                    disabled: isAdmin(user) ? false : true,
                                    className: isAdmin(user)
                                       ? ""
                                       : classes.disabled,
                                 }}
                              />
                           </span>
                        </Tooltip>
                     </GridItem>
                     <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                           labelText="Namn"
                           id="name"
                           formControlProps={{
                              fullWidth: true,
                           }}
                           inputProps={{
                              onChange: (e) => {
                                 setEditProfile({
                                    ...editProfile,
                                    name: e.target.value,
                                 });
                              },
                              value: editProfile.name,
                           }}
                        />
                     </GridItem>
                     <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                           labelText="Typ"
                           id="type"
                           formControlProps={{
                              fullWidth: true,
                           }}
                           inputProps={{
                              onChange: (e) => {
                                 setEditProfile({
                                    ...editProfile,
                                    type: e.target.value,
                                 });
                              },
                              value: editProfile.type,
                           }}
                        />
                     </GridItem>
                     <GridItem>
                        <CustomInput
                           labelText="Beskrivning"
                           id="description"
                           formControlProps={{
                              fullWidth: true,
                              className: classes.textArea,
                           }}
                           inputProps={{
                              multiline: true,
                              rows: 3,
                              onChange: (e) => {
                                 setEditProfile({
                                    ...editProfile,
                                    description: e.target.value,
                                 });
                              },
                              value: editProfile.description,
                           }}
                        />
                     </GridItem>
                     <GridItem>
                        <CustomInput
                           labelText="Detaljerad info"
                           id="moreInfo"
                           formControlProps={{
                              fullWidth: true,
                              className: classes.textArea,
                           }}
                           inputProps={{
                              multiline: true,
                              rows: 4,
                              onChange: (e) => {
                                 setEditProfile({
                                    ...editProfile,
                                    moreinfo: e.target.value,
                                 });
                              },
                              value: editProfile.moreinfo,
                           }}
                        />
                     </GridItem>
                     <GridItem xs={12} md={6}>
                        <CustomInput
                           labelText="Facebook"
                           id="facebook"
                           formControlProps={{
                              fullWidth: true,
                           }}
                           inputProps={{
                              onChange: (e) => {
                                 setEditProfile({
                                    ...editProfile,
                                    facebook: e.target.value,
                                 });
                              },
                              value: editProfile.facebook,
                           }}
                        />
                     </GridItem>
                     <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                           labelText="Twitter"
                           id="twitter"
                           formControlProps={{
                              fullWidth: true,
                           }}
                           inputProps={{
                              onChange: (e) => {
                                 setEditProfile({
                                    ...editProfile,
                                    twitter: e.target.value,
                                 });
                              },
                              value: editProfile.twitter,
                           }}
                        />
                     </GridItem>
                     <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                           labelText="Instagram"
                           id="instagram"
                           formControlProps={{
                              fullWidth: true,
                           }}
                           inputProps={{
                              onChange: (e) => {
                                 setEditProfile({
                                    ...editProfile,
                                    instagram: e.target.value,
                                 });
                              },
                              value: editProfile.instagram,
                           }}
                        />
                     </GridItem>
                     <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                           labelText="LinkedIn"
                           id="linkedin"
                           formControlProps={{
                              fullWidth: true,
                           }}
                           inputProps={{
                              onChange: (e) => {
                                 setEditProfile({
                                    ...editProfile,
                                    linkedin: e.target.value,
                                 });
                              },
                              value: editProfile.linkedin,
                           }}
                        />
                     </GridItem>
                  </GridContainer>
               </form>
            </GridItem>
            <Grid item xs={12} sx={{ minHeight: "5em" }}>
               <Grid
                  sx={{ flexGrow: 1 }}
                  container
                  justifyContent="center"
                  spacing={3}
               >
                  {isSelectedEmtpy() && (
                     <Grid item>
                        <div>
                           <Button
                              onClick={handleSaveNewProfile}
                              startIcon={<GroupAdd />}
                              color="primary"
                              disabled={isProfileEqual(
                                 selectedProfile,
                                 editProfile
                              )}
                           >
                              Lägg till profil
                           </Button>
                        </div>
                     </Grid>
                  )}
                  {!isSelectedEmtpy() && (
                     <Grid item>
                        <div>
                           <Button
                              onClick={handleUpdateProfile}
                              startIcon={<GroupAdd />}
                              color="primary"
                           >
                              Spara profil
                           </Button>
                        </div>
                     </Grid>
                  )}
                  <Grid item>
                     <div>
                        <Button
                           color="warning"
                           onClick={() => setShowConfirmDelete(true)}
                           startIcon={<DeleteIcon />}
                           disabled={selectedProfile.email === ""}
                        >
                           Ta bort profil
                        </Button>
                     </div>
                  </Grid>
               </Grid>
            </Grid>
         </GridContainer>
         <Snackbar
            autoHideDuration={5000}
            open={snackMessage ? true : false}
            onClose={handleSnackClose}
            //TransitionComponent={state.Transition}
            message={snackMessage}
            key={!"state.Transition.name"}
         >
            <Alert
               onClose={handleSnackClose}
               severity="success"
               sx={{ width: "100%" }}
            >
               {snackMessage}
            </Alert>
         </Snackbar>
         <CustomConfirm
            title="Ta bort?"
            message="Profilen kommer att tas bort permanent och kan inte återskapas. Vill du radera profilen?"
            handleCancel={() => setShowConfirmDelete(false)}
            handleOk={handleConfirmDeleteProfile}
            show={showConfirmDelete}
         />
      </div>
   );
}
