import React from "react";
import { useAuthUser } from "@frontegg/react";
import "assets/scss/material-kit-react.scss?v=1.10.0";

import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import ProtectedRoute from "./core/ProtectedRoute";
import LoginPage from "views/LoginPage/LoginPage.js";
import AboutPage from "views/AboutPage/AboutPage.js";
import StartPage from "views/StartPage/StartPage.js";
import ProfilePage from "views/ProfilePage/ProfilePage.js";
import AllProfiles from "views/AllProfiles/AllProfiles";
import ComponentsPage from "views/Components/Components.js";
import EditProfilesPage from "views/EditProfilesPage/EditProfilesPage";

const PageNotFound = () => (
   <Box sx={{ margin: "auto auto", width: "15em" }}>
      <h2>Sida finns inte</h2>
      <a href="/">Till startsida</a>
   </Box>
);

export let userInfo = {
   isLocalhost:
      location.hostname === "localhost" || location.hostname === "127.0.0.1"
         ? true
         : false,
   isUserAuthenticated: false,
   context: null,
};

export const BrandContext = React.createContext("Texia & Hoij");
export const UserContext = React.createContext(userInfo);

const App = () => {
   const isAuthenticated = () => (user?.name ? true : false);
   const user = useAuthUser();
   userInfo.isUserAuthenticated = isAuthenticated();
   userInfo.context = user;

   return (
      <div className="App">
         {isAuthenticated && (
            <BrandContext.Provider value="Texia & Hoij AB">
               <UserContext.Provider value={userInfo}>
                  <Routes>
                     <Route path="*" element={<PageNotFound />} />
                     <Route path="/" element={<StartPage />} />
                     <Route path="/login-page" element={<LoginPage />} />
                     <Route path="/about-page" element={<AboutPage />} />
                     <Route
                        path="/profile-page"
                        element={
                           <ProtectedRoute role={["Admin"]}>
                              <ProfilePage />
                           </ProtectedRoute>
                        }
                     />
                     <Route
                        path="/all-profiles"
                        element={
                           <ProtectedRoute>
                              <AllProfiles />
                           </ProtectedRoute>
                        }
                     />
                     <Route
                        path="/edit-page"
                        element={
                           <ProtectedRoute role={["ConsultantBasic", "Admin"]}>
                              <EditProfilesPage />
                           </ProtectedRoute>
                        }
                     />
                     <Route path="/components" element={<ComponentsPage />} />
                     <Route path="*" element={<PageNotFound />} />
                  </Routes>
               </UserContext.Provider>
            </BrandContext.Provider>
         )}
      </div>
   );
};

export default App;
