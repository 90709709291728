import React from "react";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
// @mui / @material-ui icons
import Palette from "@material-ui/icons/Palette";
import DescriptionIcon from "@mui/icons-material/Description";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import NavPills from "components/NavPills/NavPills.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/system";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
   const classes = useStyles();
   const { ...rest } = props;
   const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
   );

   const location = useLocation();
   const profile = location.state?.profile;

   return (
      <div>
         <Header
            color="transparent"
            brand="Material Kit React"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
               height: 150,
               color: "white",
            }}
            checkPageTitleId="page-start"
            {...rest}
         />
         <Parallax
            small
            filter
            image={require("assets/img/profile-bg.jpg").default}
         />
         <div className={classNames(classes.main, classes.mainRaised)}>
            <div id="page-start">
               <div className={classes.container}>
                  <GridContainer justify="center">
                     <GridItem xs={12} sm={10} md={8} lg={6}>
                        <div className={classes.profile}>
                           <div>
                              <img
                                 src={profile && profile.alternative_image}
                                 alt="..."
                                 className={imageClasses}
                              />
                           </div>
                           <div className={classes.name}>
                              <h3 className={classes.title}>
                                 {profile && profile.name}
                              </h3>
                              <h6>{profile?.type}</h6>
                              {profile.twitter && (
                                 <Button
                                    justIcon
                                    link
                                    className={classes.margin5}
                                    href={profile.twitter}
                                    target="_blank"
                                 >
                                    <i className={"fab fa-twitter"} />
                                 </Button>
                              )}
                              {profile.instagram && (
                                 <Button
                                    justIcon
                                    link
                                    className={classes.margin5}
                                    href={profile.instagram}
                                    target="_blank"
                                 >
                                    <i className={"fab fa-instagram"} />
                                 </Button>
                              )}
                              {profile.facebook && (
                                 <Button
                                    justIcon
                                    link
                                    className={classes.margin5}
                                    href={profile.facebook}
                                    target="_blank"
                                 >
                                    <i className={"fab fa-facebook"} />
                                 </Button>
                              )}
                              {profile.linkedin && (
                                 <Button
                                    justIcon
                                    link
                                    className={classes.margin5}
                                    href={profile.linkedin}
                                    target="_blank"
                                 >
                                    <i className={"fab fa-linkedin"} />
                                 </Button>
                              )}
                           </div>
                        </div>
                     </GridItem>
                  </GridContainer>
                  <GridContainer justify="center">
                     <GridItem xs={12} className={classes.navWrapper}>
                        <NavPills
                           alignCenter
                           color="primary"
                           tabs={[
                              {
                                 tabButton: "Beskrivning",
                                 tabIcon: DescriptionIcon,
                                 tabContent: (
                                    <GridContainer justify="center">
                                       <GridItem xs={12} sm={10} md={8} lg={6}>
                                          <Box className={classes.description}>
                                             <p>
                                                {profile && profile.moreinfo}
                                             </p>
                                          </Box>
                                       </GridItem>
                                    </GridContainer>
                                 ),
                              },
                              {
                                 tabButton: "Portfolio",
                                 tabIcon: Palette,
                                 tabContent: (
                                    <GridContainer justify="center">
                                       <GridItem xs={12} sm={10} md={8} lg={6}>
                                          <Box
                                             className={
                                                classes.descriptionJustify
                                             }
                                          >
                                             <p>
                                                {profile && profile.moreinfo}
                                             </p>
                                          </Box>
                                       </GridItem>
                                    </GridContainer>
                                 ),
                              },
                           ]}
                        />
                     </GridItem>
                  </GridContainer>
               </div>
            </div>
         </div>
         <Footer />
      </div>
   );
}
