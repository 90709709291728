import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Slide from "@mui/material/Slide";
import React from "react";
import { PropTypes } from "prop-types";
// import Grow from '@mui/material/Grow';

const Transition = React.forwardRef(function Transition(props, ref) {
   return <Slide direction="up" ref={ref} {...props} />;
});

const CustomConfirm = ({ title, message, handleOk, handleCancel, show }) => {
   React.useEffect(() => {
      console.log("confirm: ", show);
   }, []);

   return (
      <Dialog
         open={show}
         TransitionComponent={Transition}
         aria-describedby="alert-dialog-slide-description"
      >
         <DialogTitle>{title}</DialogTitle>
         <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
               {message}
            </DialogContentText>
         </DialogContent>
         <DialogActions>
            <Button onClick={handleCancel}>Avbryt</Button>
            <Button onClick={handleOk}>Ok</Button>
         </DialogActions>
      </Dialog>
   );
};

CustomConfirm.propTypes = {
   title: PropTypes.string,
   message: PropTypes.string,
   show: PropTypes.bool,
   handleCancel: PropTypes.func,
   handleOk: PropTypes.any,
};

export default CustomConfirm;
