import React, { useContext, useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import { useRef } from "react";

import styles from "assets/jss/material-kit-react/views/loginPage.js";

import image from "assets/img/bg7.jpg";
import { UserContext } from "app";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(styles);

const HashMe = function (str, seed = 0) {
   let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
   for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
   }
   h1 =
      Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^
      Math.imul(h2 ^ (h2 >>> 13), 3266489909);
   h2 =
      Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^
      Math.imul(h1 ^ (h1 >>> 13), 3266489909);
   return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

export default function LoginPage() {
   const location = useLocation();

   useEffect(() => {
      console.log(
         "loginPage locataion.state.from: " + JSON.stringify(location)
      );
   }, []);

   const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
   setTimeout(function () {
      setCardAnimation("");
   }, 700);
   const classes = useStyles();
   //const { ...rest } = props;

   const [password, setPassword] = useState("");
   const userContext = useContext(UserContext);
   const navigate = useNavigate();
   const buttonRef = useRef();

   const CheckHashedPassword = () => {
      const pwd1 = 5941936106282949;
      let pwd2 = HashMe(password);
      const pass = pwd1 === pwd2;
      // console.log(`${pwd1}/${pwd2}`);
      console.log("check: " + pass);
      return true; //pass;
   };

   const onPasswordChange = (e) => {
      if (e.target.keyCode === 13) {
         buttonRef.current.click();
      }
      setPassword(e.target.value);
   };

   const LoginButtonHandler = () => {
      userContext.isUserAuthenticated = CheckHashedPassword();
      if (userContext.isUserAuthenticated) {
         const to = location.state?.location ? location.state.location : "/";
         navigate(to, { replace: true });
      }
   };

   const HandleEnterPress = (e) => {
      if (e.key === "Enter") {
         e.preventDefault();
         buttonRef.current.click();
         return false;
      }
   };

   return (
      <div>
         {/* <Header
        color="transparent"
        changeColorOnScroll={{
          height: 3000,
          color: "red",
        }}
        {...rest}
      /> */}
         <div
            className={classes.pageHeader}
            style={{
               backgroundImage: "url(" + image + ")",
               backgroundSize: "cover",
               backgroundPosition: "top center",
            }}
         >
            <div className={classes.container}>
               <GridContainer justify="center">
                  <GridItem xs={12} sm={12} md={4}>
                     <Card
                        className={classes[cardAnimaton]}
                        onKeyPress={HandleEnterPress}
                     >
                        {/* <form className={classes.form} onKeyPress={HandleEnterPress}> */}
                        <CardHeader
                           color="primary"
                           className={classes.cardHeader}
                        >
                           <h4>Login</h4>
                           <div className={classes.socialLine}>
                              <Button
                                 justIcon
                                 href="#pablo"
                                 target="_blank"
                                 color="transparent"
                                 onClick={(e) => e.preventDefault()}
                              >
                                 <i className={"fab fa-twitter"} />
                              </Button>
                              <Button
                                 justIcon
                                 href="#pablo"
                                 target="_blank"
                                 color="transparent"
                                 onClick={(e) => e.preventDefault()}
                              >
                                 <i className={"fab fa-facebook"} />
                              </Button>
                              <Button
                                 justIcon
                                 href="#pablo"
                                 target="_blank"
                                 color="transparent"
                                 onClick={(e) => e.preventDefault()}
                              >
                                 <i className={"fab fa-google-plus-g"} />
                              </Button>
                           </div>
                        </CardHeader>
                        <p className={classes.divider}>
                           Ange lösen för att visa
                        </p>
                        <CardBody>
                           {/* <CustomInput<
                      labelText="First Name..."
                      id="first"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "text",
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                           {/* <CustomInput
                      labelText="Email..."
                      id="email"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        type: "email",
                        endAdornment: (
                          <InputAdornment position="end">
                            <Email className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                           <CustomInput
                              labelText="Lösen"
                              id="pass"
                              formControlProps={{
                                 fullWidth: true,
                              }}
                              inputProps={{
                                 defaultValue: { password },
                                 type: "password",
                                 onChange: onPasswordChange,
                                 endAdornment: (
                                    <InputAdornment position="end">
                                       <Icon
                                          className={classes.inputIconsColor}
                                       >
                                          lock_outline
                                       </Icon>
                                    </InputAdornment>
                                 ),
                                 autoComplete: "off",
                              }}
                           />
                        </CardBody>
                        <CardFooter className={classes.cardFooter}>
                           <Button
                              simple
                              color="primary"
                              size="lg"
                              onClick={LoginButtonHandler}
                              ref={buttonRef}
                           >
                              Kör
                           </Button>
                        </CardFooter>
                     </Card>
                  </GridItem>
               </GridContainer>
            </div>
            <Footer whiteFont />
         </div>
      </div>
   );
}
