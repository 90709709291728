import axios from "axios";

const baseUrl = "http://localhost/api/index.php";
const baseUrl_prod = "https://hoijer.net/api/index.php";

const createInstance = (jwt) => {
   let useUrl = baseUrl_prod;
   if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
      useUrl = baseUrl;
   }
   console.log("using url: ", useUrl);

   const instance = axios.create({
      baseURL: useUrl,
      timeout: 5000,
      headers: { "X-Custom-Header": "foobar", Authorization: `Bearer ${jwt}` },
   });

   return instance;
};

export const fetchAllProfiles = (jwt, setProfiles) => {
   const instance = createInstance(jwt);
   // console.log(jwt);
   instance
      .get("/allProfiles")
      .then((resp) => {
         console.log("fetchAllProfiles:");
         console.log(resp.data);
         setProfiles(resp.data);
      })
      .catch((error) => {
         console.error("fetch Error:", error);
      });
};

export const fetchUserProfile = (jwt, profileMail, setUserProfile) => {
   const instance = createInstance(jwt);
   console.log(profileMail);
   if (profileMail) {
      instance
         .get(`/profile/${profileMail}`)
         .then((resp) => {
            console.log("fetchUserProfile:");
            console.log(resp.data);
            setUserProfile(resp?.data[0]);
         })
         .catch((error) => {
            console.error("fetch Error:", error);
         });
   } else {
      setUserProfile = null;
   }
};

export const saveNewProfile = (jwt, profile) => {
   const instance = createInstance(jwt);
   console.log("saveNewProfile", jwt, profile);
   instance
      .post("/saveNewProfile", profile)
      .then((resp) => {
         console.log("sparat profil:");
         console.log(resp.data);
      })
      .catch((error) => {
         console.error("saveNewProfile Error =>", error.response.data);
      });
};

export const saveNewProfilePromise = (jwt, profile) => {
   return new Promise(function (resolve, reject) {
      const instance = createInstance(jwt);
      console.log("saveNewProfile", jwt, profile);
      instance
         .post("/saveNewProfile", profile)
         .then((resp) => {
            console.log("Promise sparat profil:");
            console.log(resp);
            resolve(resp);
         })
         .catch((error) => {
            console.error("Promise saveNewProfile Error =>", error);
            console.log(error.response);
            reject(error.response);
         });
   });
};

export const updateProfile = (jwt, profile) => {
   const instance = createInstance(jwt);
   console.log("/updateProfile", jwt, profile);
   instance
      .post("/updateProfile", profile)
      .then((resp) => {
         console.log("updaterat profil:");
         console.log(resp?.data);
      })
      .catch((error) => {
         console.error("updateProfile Error:", error);
      });
};

export const updateProfilePromise = (jwt, profile) => {
   return new Promise(function (resolve, reject) {
      const instance = createInstance(jwt);
      instance
         .post("/updateProfile", profile)
         .then((resp) => {
            console.log("Promise updaterat profil:");
            console.log(resp?.data);
            resolve(resp);
         })
         .catch((error) => {
            console.error("Promise uppdatera Error:", error);
            reject(error);
         });
   });
};

export const deleteProfilePromise = (jwt, profile) => {
   return new Promise(function (resolve, reject) {
      const instance = createInstance(jwt);
      instance
         .delete(`/deleteProfile/${profile.user_id}`)
         .then((resp) => {
            console.log("Promise raderat profil:");
            console.log(resp?.data);
            resolve(resp);
         })
         .catch((error) => {
            console.error("Promise raderat Error:", error);
            reject(error);
         });
   });
};

export const sendNewUserMail = (userContext) => {
   const instance = createInstance(userContext.accessToken);
   console.log("sendNewUserMail: ", userContext);
   const userData = { userMail: userContext.email, userName: userContext.name };
   instance
      .post("/newUserLoginNotice", userData)
      .then((resp) => {
         console.log("skickat mail:");
         console.log(resp.data);
      })
      .catch((error) => {
         console.error("sendNewUserMail Error =>", error.response.data);
      });
};

// NewUser calls
// newuser:
// {
//    email: "string",
//    status: "smailint",
// }
export const saveNewUser = (jwt, newUser) => {
   const instance = createInstance(jwt);
   console.log("saveNewUser", jwt, newUser);
   instance
      .post("/saveNewUser", newUser)
      .then((resp) => {
         console.log("sparat ny user:");
         console.log(resp.data);
      })
      .catch((error) => {
         console.error("saveNewUser Error =>", error.response.data);
      });
};
